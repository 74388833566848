import React from "react"

const HashTags = props => {
  return (
    <div className={props.mainCss}>{getHashtag(props.list, props.itemCss)}</div>
  )
}

function getHashtag(items, itemCss) {
  let objReturn = []

  if (items) {

    let iCount = 0
    Object.keys(items).forEach(element => {

      try {
        var re = / /gi
        let sTag = items[element].toString()
        sTag = sTag.toLowerCase()
        sTag = sTag.replace(re, "_")
        objReturn.push(
          <span key={"hash_" + iCount.toString()} className={itemCss}>
            #{sTag}
          </span>
        )
        iCount ++

      } catch {
        console.log("Errore con: " + element)
      }
      
    })

  }

  return objReturn
}

export default HashTags


/*


  if (items && items.length > 0) {
    Object.keys(items).forEach(function (val, index) {
      var re = / /gi
      let sTag = items[index].toString()
      sTag = sTag.toLowerCase()
      sTag = sTag.replace(re, "_")

      objReturn.push(
        <span key={"hash_" + index.toString()} className={itemCss}>
          #{sTag}
        </span>
      )
    })
  }


*/