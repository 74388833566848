import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HashTags from "../components/hashtags"
import NavLink from "../components/navlink"

const Partners = props => (
  <StaticQuery
    query={graphql`
      query {
        allPartnersJson {
          edges {
            node {
              titolo
              testo
              url
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 140
                    quality: 60
                    aspectRatio: 1.5
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Layout location="/partners" title="Partners">
          <Seo title="Partners" />

          <h1 classNameName="text-center text-3xl mb-8">
            The Platinum Services | Dmc Italy
          </h1>
          <h2 classNameName="text-center text-2xl mb-14">
            Hotel Partners | Italy | Europe
          </h2>

          <p classNameName="text-xl mb-8 text-center">
            The staff of The Platinum Services Dmc Italy and Events, throughout
            the years, has carefully selected and personally visited a number of
            hotels, including luxury, boutique, and resort properties.
            <br />
            Every year, we go through them again to make sure you're comfortable
            and safe.
          </p>
          {getPartners(data.allPartnersJson.edges)}
        </Layout>
      </>
    )}
  />
)

function getPartners(partners) {
  let objRow = []

  Object.keys(partners).forEach(function (val, index) {
    let partner = partners[val].node
    objRow.push(getCol(partner, index))
  })

  return <div classNameName="flex flex-wrap overflow-hidden">{objRow}</div>
}
function getCol(partner, index) {

  return (
    <div key={"partner_" + index.toString()} className="bg-white shadow-md rounded-md xl:my-4 xl:p-1 w-4/12">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">Titolo della Card</div>
        <p className="text-gray-700 text-base">Contenuto della card. Puoi aggiungere qui tutte le informazioni che desideri.</p>
      </div>
      <div className="px-6 py-4">
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">#Tag1</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">#Tag2</span>
      </div>
    </div>
  )
}

export default Partners
/*

  return (
    <div key={"partner_" + index.toString()} classNameName={sclassNameName}>

      <div>
        <h3 classNameName="font-bold text-center">{partner.titolo}</h3>
        <GatsbyImage
          imgclassNameName="float-right"
          imgStyle={{
            WebkitBorderRadius: "20px",
            MozBorderRadius: "20px",
            borderRadius: "20px",
          }}
          loading="eager"
          image={getImage(partner.image)}
          alt={"Logo " + partner.titolo}
        />
        <p>{partner.testo}</p>
      </div>

    </div>
  )










export default function FeaturedPartners() {
  let keywords = [
    "theplatininumservices",
    "dmc Italy",
    "hotels",
    "genoa",
    "rome",
    "roma",
    "comolake",
    "como",
    "moltrasio",
    "alagna",
    "monterosa",
    "salento",
    "puglia",
    "apulia",
    "siracusa",
    "syracuse",
    "tuscany",
    "golfcourse",
    "skiresort",
    "mirahotels",
    "miraresorts",
    "fivestarhotels",
    "fourstarhotels",
    "private jet",
    "airtravel",
    "radissonbluGHR",
    "radissonblu",
    "grandhotelimperiale",
    "bristol palace hotel",
    "4L collection",
    "thehoxtonrome",
    "luxury",
    "logistics",
    "hotels",
    "resort properties",
    "boutique hotels",
    "uniquememories",
    "unforgettableexperiences",
    "anantara",
    "#preferred",
    "#lungarno collection",
    "florence",
    "firenze",
    "europe",
    "worldwide"
  ]

  let imgWidth = 140
  let iLeft = 1
  let cLeft = `overflow-hidden xl:my-4 w-${iLeft.toString()}/12`
  let cRight = `overflow-hidden xl:my-4 xl:p-1 w-${(12 / 2 - iLeft).toString()}/12`


  return (
    <Layout location="/partners" title="Partners">
      <Seo title="Partners" />
      <h1 classNameName="text-center text-3xl mb-8">
        The Platinum Services | Dmc Italy{" "}
      </h1>
      <h2 classNameName="text-center text-2xl mb-14">
        Hotel Partners | Italy | Europe
      </h2>

      <p classNameName="text-xl mb-8 text-center">
        The staff of The Platinum Services Dmc Italy and Events, throughout the
        years, has carefully selected and personally visited a number of hotels,
        including luxury, boutique, and resort properties.
        <br />
        Every year, we go through them again to make sure you're comfortable and
        safe.
      </p>

      <div classNameName="flex flex-wrap overflow-hidden">
        

      </div>

      <HashTags
        key="hashtags"
        list={keywords}
        mainCss="my-1 py-6 px-1 w-full overflow-hidden text-right"
        itemCss="inline-block px-1 text-xs font-normal text-gray-400 mr-2 mb-2"
      />
    </Layout>
  )
}





        <div classNameName={cLeft}>
          <StaticImage
            src={"../images/partners/lungarno.png"}
            alt="Lungarno Collection Florence"
            width={imgWidth}
          />
        </div>
        <div classNameName={cRight}>
          <h3 classNameName="font-bold">Lungarno Collection Florence</h3>
          The age-old art of hospitality should follow precise rules in which the most important figure is the guest and his or her needs and feelings. 
          Our hotels in Florence follow this principle in offering themselves to the visitor who comes to stay in the historic “Cradle of the Renaissance. 
          ”This is the spirit that has driven  to create  4- and 5-star luxury hotels, all located near Ponte Vecchio and a stone’s throw from the Uffizi Gallery, 
          the Duomo, Piazza della Signoria and Palazzo Pitti, in a perfect position to be captivated by the magnificence of the art and architecture of Florence.
        </div>







        <div classNameName={cLeft}>foto</div>
        <div classNameName={cRight}>testo</div>



        

          <div classNameName={colPartner}>
            <div classNameName={colLeft}>
            immagine
            </div>

            <div classNameName={colRight}>
              <h3 classNameName="font-bold">titolo</h3>
              Testo
            </div>
          </div>



*/
